<template>
  <!-- 删除具体原因Popover -->
  <el-dialog
    title=""
    :visible.sync="$store.state.deleteReason.deletePopoverVisible"
    custom-class="delete-reason-dialog"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <div v-if="type == -2 && details">
      <div class="delete-reason-header">
        {{ $t("deletionReason") }}:{{ details.violationDescription }}
      </div>

      <div class="delete-reason-body">
        <div class="delete-reason-user">
          <div class="user-head">
            <img
              :src="details.profilePhoto"
              style="width: 50px; height: 50px"
              alt=""
            />
          </div>
          <div>
            <div class="user-name">
              {{ details.name }}
            </div>
            <div class="user-time">
              {{ details.publishTime }}
            </div>
          </div>
        </div>
        <h3 class="delete-reason-title">
          {{ details.iCanDo }}
        </h3>
        <div class="delete-reason-content" v-html="details.richDetail"></div>
        <!-- 图片 -->
        <div
          class="delete-reason-imgs"
          v-if="
            details && details.attachments && details.attachments.length > 0
          "
        >
          <img
            v-for="item in details.attachments"
            :src="item.path"
            :key="item.id"
            alt=""
          />
          <video
            v-if="details.attachments && details.attachments[0].type == 1"
            :src="details.attachments[0].path"
            controls
          ></video>
        </div>
      </div>
    </div>
    <div v-if="type == -21 && postReplayDetails">
      <div class="delete-reason-header">
        {{ $t("deletionReason") }}:{{
          postReplayDetails.deleteRemarkDescription
        }}
      </div>
      <div class="delete-reason-body">
        <div class="delete-reason-user">
          <div class="user-head">
            <img
              :src="userInfo.avatar"
              style="width: 50px; height: 50px"
              alt=""
            />
          </div>
          <div>
            <div class="user-name">
              {{ userInfo.account }}
            </div>
            <div class="user-time">
              {{ postReplayDetails.reviewDatetime }}
            </div>
          </div>
        </div>
        <h3 class="delete-reason-title">
          {{ postReplayDetails.iCanDo }}
        </h3>
        <div
          class="delete-reason-content"
          v-html="reconvert(postReplayDetails.content)"
        ></div>
      </div>
    </div>
    <div v-if="type == -9 && groupInfo">
      <div class="delete-reason-header">
        {{ $t("deletionReason") }}:{{ groupInfo.violationDescription }}
      </div>
      <div class="delete-reason-body">
        <div class="delete-reason-user">
          <div class="user-head groupInfo">
            <img
              :src="groupInfo.group_header"
              style="width: 50px; height: 50px"
              alt=""
            />
            <span
              class="iconfont_Me iconStyle icon-open-fill"
              style="color: rgb(51, 51, 51)"
            ></span>
          </div>
          <div>
            <div class="user-name">
              {{ groupInfo.group_name }}
            </div>
            <div class="user-time">
              {{ groupInfo.join_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户评论 -->
    <div v-if="type == -11 && userReplayDetails">
      <div class="delete-reason-header">
        {{ $t("deletionReason") }}:{{
          userReplayDetails.deleteRemarkDescription
        }}
      </div>
      <div class="delete-reason-body">
        <div class="delete-reason-user">
          <div class="user-head">
            <img
              :src="userInfo.avatar"
              style="width: 50px; height: 50px"
              alt=""
            />
          </div>
          <div>
            <div class="user-name">
              {{ userInfo.account }}
            </div>
            <div class="user-time">
              {{ userReplayDetails.reviewDatetime }}
            </div>
          </div>
        </div>
        <h3 class="delete-reason-title">
          {{ userReplayDetails.iCanDo }}
        </h3>
        <div
          class="delete-reason-content"
          v-html="reconvert(userReplayDetails.content)"
        ></div>
      </div>
    </div>
    <!-- 群评论 -->
    <div v-if="type == -91 && groupReplayDetails">
      <div class="delete-reason-header">
        {{ $t("deletionReason") }}:{{
          groupReplayDetails.deleteRemarkDescription
        }}
      </div>
      <div class="delete-reason-body">
        <div class="delete-reason-user">
          <div class="user-head">
            <img
              :src="userInfo.avatar"
              style="width: 50px; height: 50px"
              alt=""
            />
          </div>
          <div>
            <div class="user-name">
              {{ userInfo.account }}
            </div>
            <div class="user-time">
              {{ groupReplayDetails.reviewDatetime }}
            </div>
          </div>
        </div>
        <h3 class="delete-reason-title">
          {{ groupReplayDetails.iCanDo }}
        </h3>
        <div
          class="delete-reason-content"
          v-html="reconvert(groupReplayDetails.content)"
        ></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getPostInformation } from "@/api/newVersion/postContentDetails";
import { getGroupDetail } from "@/api/newVersion/groupCenter";
import { getDeleteReplays } from "@/api/rest";
export default {
  data() {
    return {
      details: null,
      type: null,
      userInfo: null,
      groupInfo: null,
      groupReplayDetails: null, //群评论详情
      postReplayDetails: null, //帖子评论详情
      userReplayDetails: null, //用户评论详情
      deleteReportRecordId: null,
    };
  },
  props: {},
  watch: {
    "$store.state.deleteReason.deleteResonId": {
      handler(val) {
        if (val) {
          let resonid = val.split("d")[0];
          this.getDetails(resonid);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
  },
  methods: {
    async getDetails(val) {
      let type = this.$store.state.deleteReason.deleteResonType;
      this.type = type;
      let result;
      switch (type) {
        case -2:
          //获取删除帖子详情
          result = await getPostInformation(
            val,
            this.$store.state.deleteReason.deleteReportRecordId
          );
          this.details = result.data.data;
          break;
        case -21:
          //获取删除帖子评论详情
          result = await getDeleteReplays(
            val,
            1,
            this.$store.state.deleteReason.deleteReportRecordId
          );
          this.postReplayDetails = result.data.data;
          break;
        case -9:
          //获取删除群详情
          await this.getGroupInfoById(
            val,
            this.$store.state.deleteReason.deleteReportRecordId
          );
        case -91:
          //获取删除群评论详情
          result = await getDeleteReplays(
            val,
            4,
            this.$store.state.deleteReason.deleteReportRecordId
          );
          this.groupReplayDetails = result.data.data;
        case -11:
          //获取删除用户评论详情
          result = await getDeleteReplays(
            val,
            3,
            this.$store.state.deleteReason.deleteReportRecordId
          );
          this.userReplayDetails = result.data.data;
          break;
        default:
          break;
      }
    },
    // 根据ID获取群组的详细信息
    async getGroupInfoById(groupId, violationRecordId) {
      if (groupId) {
        let params = {
          groupId: groupId,
          userId: this.$store.getters.userInfo.id || "",
          violationRecordId,
        };
        let result = await getGroupDetail(params);
        if (result.code == 1) {
          this.groupInfo = result.data[0];
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    //取消操作
    dialogVisible() {
      this.$store.commit("setDeletePopoverVisible", false);
    },
    beforeClose() {
      this.dialogVisible();
    },
    // 将Unicode转化为评论
    reconvert(str) {
      if (str) {
        str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
          );
        });
        str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
          );
        });
        str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
          );
        });
        return str;
      }
    },
  },
};
</script>

<style lang="scss">
// @media screen and (min-width: 100px) {
//   .delete-reason-dialog {
//     width: 70% !important;
//   }
// }

// @media screen and (min-width: 768px) {
//   .delete-reason-dialog {
//     width: 70% !important;
//   }
// }
// @media screen and (min-width: 992px) {
//   .delete-reason-dialog {
//     width: 50% !important;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .delete-reason-dialog {
//     width: 30% !important;
//   }
// }
.delete-reason-dialog {
  width: 600px !important;
  overflow: hidden;
  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    /**/
  }
  div::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  div::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }
  div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
  div::-webkit-scrollbar-corner {
    background: #179a16;
  }

  max-height: 60%;
  user-select: none;
  .el-dialog__header {
    background: #f0f0f0;
  }
  .el-dialog--center,
  .el-dialog__body {
    padding: 0 !important;
    border-radius: 8px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.06);
    // height: 724px;
  }
  .delete-reason-header {
    height: 42px;
    background: #f0f0f0;
    padding: 0 30px;
    font-size: 15px;
    font-weight: 700;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .delete-reason-body {
    overflow: auto;
    max-height: 500px;
    padding: 30px;
    .delete-reason-user {
      display: flex;
      align-items: center;
      .user-head {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: flex;
        &.groupInfo {
          margin-right: 30px;
        }
        span {
          margin-left: 4px;
        }
        image {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 4px;
      }
      .user-time {
        font-size: 12px;
        color: #8f8f8f;
      }
    }
    .delete-reason-content {
      margin-bottom: 10px;
      img {
        max-width: 100%;
      }
    }

    .delete-reason-imgs {
      img {
        max-width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }
  }
}
</style>
