var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.$store.state.deleteReason.deletePopoverVisible,
        "custom-class": "delete-reason-dialog",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.deleteReason,
            "deletePopoverVisible",
            $event
          )
        }
      }
    },
    [
      _vm.type == -2 && _vm.details
        ? _c("div", [
            _c("div", { staticClass: "delete-reason-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deletionReason")) +
                  ":" +
                  _vm._s(_vm.details.violationDescription) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "delete-reason-body" }, [
              _c("div", { staticClass: "delete-reason-user" }, [
                _c("div", { staticClass: "user-head" }, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: { src: _vm.details.profilePhoto, alt: "" }
                  })
                ]),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.details.name) +
                        "\n          "
                    )
                  ]),
                  _c("div", { staticClass: "user-time" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.details.publishTime) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _c("h3", { staticClass: "delete-reason-title" }, [
                _vm._v("\n        " + _vm._s(_vm.details.iCanDo) + "\n      ")
              ]),
              _c("div", {
                staticClass: "delete-reason-content",
                domProps: { innerHTML: _vm._s(_vm.details.richDetail) }
              }),
              _vm.details &&
              _vm.details.attachments &&
              _vm.details.attachments.length > 0
                ? _c(
                    "div",
                    { staticClass: "delete-reason-imgs" },
                    [
                      _vm._l(_vm.details.attachments, function(item) {
                        return _c("img", {
                          key: item.id,
                          attrs: { src: item.path, alt: "" }
                        })
                      }),
                      _vm.details.attachments &&
                      _vm.details.attachments[0].type == 1
                        ? _c("video", {
                            attrs: {
                              src: _vm.details.attachments[0].path,
                              controls: ""
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm.type == -21 && _vm.postReplayDetails
        ? _c("div", [
            _c("div", { staticClass: "delete-reason-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deletionReason")) +
                  ":" +
                  _vm._s(_vm.postReplayDetails.deleteRemarkDescription) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "delete-reason-body" }, [
              _c("div", { staticClass: "delete-reason-user" }, [
                _c("div", { staticClass: "user-head" }, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: { src: _vm.userInfo.avatar, alt: "" }
                  })
                ]),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userInfo.account) +
                        "\n          "
                    )
                  ]),
                  _c("div", { staticClass: "user-time" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.postReplayDetails.reviewDatetime) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _c("h3", { staticClass: "delete-reason-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.postReplayDetails.iCanDo) +
                    "\n      "
                )
              ]),
              _c("div", {
                staticClass: "delete-reason-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.reconvert(_vm.postReplayDetails.content)
                  )
                }
              })
            ])
          ])
        : _vm._e(),
      _vm.type == -9 && _vm.groupInfo
        ? _c("div", [
            _c("div", { staticClass: "delete-reason-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deletionReason")) +
                  ":" +
                  _vm._s(_vm.groupInfo.violationDescription) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "delete-reason-body" }, [
              _c("div", { staticClass: "delete-reason-user" }, [
                _c("div", { staticClass: "user-head groupInfo" }, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: { src: _vm.groupInfo.group_header, alt: "" }
                  }),
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle icon-open-fill",
                    staticStyle: { color: "rgb(51, 51, 51)" }
                  })
                ]),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.groupInfo.group_name) +
                        "\n          "
                    )
                  ]),
                  _c("div", { staticClass: "user-time" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.groupInfo.join_time) +
                        "\n          "
                    )
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.type == -11 && _vm.userReplayDetails
        ? _c("div", [
            _c("div", { staticClass: "delete-reason-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deletionReason")) +
                  ":" +
                  _vm._s(_vm.userReplayDetails.deleteRemarkDescription) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "delete-reason-body" }, [
              _c("div", { staticClass: "delete-reason-user" }, [
                _c("div", { staticClass: "user-head" }, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: { src: _vm.userInfo.avatar, alt: "" }
                  })
                ]),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userInfo.account) +
                        "\n          "
                    )
                  ]),
                  _c("div", { staticClass: "user-time" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userReplayDetails.reviewDatetime) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _c("h3", { staticClass: "delete-reason-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.userReplayDetails.iCanDo) +
                    "\n      "
                )
              ]),
              _c("div", {
                staticClass: "delete-reason-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.reconvert(_vm.userReplayDetails.content)
                  )
                }
              })
            ])
          ])
        : _vm._e(),
      _vm.type == -91 && _vm.groupReplayDetails
        ? _c("div", [
            _c("div", { staticClass: "delete-reason-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deletionReason")) +
                  ":" +
                  _vm._s(_vm.groupReplayDetails.deleteRemarkDescription) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "delete-reason-body" }, [
              _c("div", { staticClass: "delete-reason-user" }, [
                _c("div", { staticClass: "user-head" }, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: { src: _vm.userInfo.avatar, alt: "" }
                  })
                ]),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userInfo.account) +
                        "\n          "
                    )
                  ]),
                  _c("div", { staticClass: "user-time" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.groupReplayDetails.reviewDatetime) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _c("h3", { staticClass: "delete-reason-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.groupReplayDetails.iCanDo) +
                    "\n      "
                )
              ]),
              _c("div", {
                staticClass: "delete-reason-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.reconvert(_vm.groupReplayDetails.content)
                  )
                }
              })
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }